import { inject, Injectable } from '@angular/core';

import { LoginService } from '../auth/login.service';
import { UserStore } from '../../store/user.store';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root',
})
export class InitializeAppService {
  private loginService = inject(LoginService);
  private langService = inject(TranslationService);
  private userStore = inject(UserStore);

  constructor() {}

  initializeApp(): Promise<void> {
    return new Promise((resolve) => {
      const refresh_token = localStorage.getItem('FinwaveRefreshToken');
      const token = localStorage.getItem('FinwaveAuthToken');
      if (
        token &&
        token.length > 0 &&
        refresh_token &&
        refresh_token.length > 0
      ) {
        this.loginService.setUserInfo(token, refresh_token);
        //this.langService.setLanguage(this.userStore.userInfo()!.language!);
      }
      resolve();
    });
  }
}
