import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { UserStore } from '@core/store/user.store';

export const isLoggedInGuard: CanActivateFn = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

  if (state.url.includes('/login')) {
    return !inject(UserStore).isLogged() || inject(Router).createUrlTree(['/evidences'])
  }

  return inject(UserStore).isLogged() || inject(Router).createUrlTree(['/login']);
};
