import { Routes } from '@angular/router';

import { isLoggedInGuard } from '@core/guards/is-logged-in.guard';
import { verifyResetTokenResolver } from '@core/resolvers/verify-reset-token.resolver';

export const routes: Routes = [
  {
  path: '',
  children: [
    {
      path: '',
      loadChildren: () => import('./pages/admin/admin.routes'),
      canActivate: [isLoggedInGuard],
    },
    {
      path: 'login',
      loadComponent: () => import('./pages/login/login-page.component'),
      canActivate: [isLoggedInGuard],
    },
    {
      path: 'login/:resetCode',
      loadComponent: () => import('./pages/login/login-page.component'),
      resolve: { codeIsValid: verifyResetTokenResolver },
    },
    {
      path: 'docs',
      loadComponent: () => import('./pages/docs/docs-page.component').then(m => m.DocsPageComponent),
      canActivate: [isLoggedInGuard],
    },
    {
      path: '**',
      loadComponent: () => import('./pages/404/not-found-page.component').then(m => m.NotFoundPageComponent),
    }
  ]
  }
];
