import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ResetPassService } from '@core/services/auth/reset-pass.service';
import { catchError, map, of } from 'rxjs';

export const verifyResetTokenResolver: ResolveFn<boolean> = (route) => {

  const resetPassService = inject(ResetPassService);
  const resetCode = route.url[1].path;

  return resetPassService.verifyResetCode(resetCode).pipe(
    map(() => true),
    catchError((error) => {
      console.error('Error verifying reset code:', error);
      return of(false);
    })
  );

};
