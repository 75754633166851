import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { catchError, switchMap, throwError } from 'rxjs';

import { LoginService } from '../services/auth/login.service';
import { LocalStorageService } from '@core/services/auth/local-storage.service';
import { Error500HandlerService } from '@core/services/utilities/error-500-handler.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.headers.has('X-Skip-Interceptor')) {
    return next(req);
  }

  const loginService = inject(LoginService);
  const localStorageService = inject(LocalStorageService);
  const handler500 = inject(Error500HandlerService);

  const authToken = localStorageService.getAuthToken();

  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  return next(authReq).pipe(
    catchError((err) => {
      if (err.status === 500) {
        handler500.isError500.set(true);
      }
      if (err.status !== 403) {
        return throwError(() => err);
      }

      return loginService.refreshToken().pipe(
        switchMap((res) => {
          localStorageService.setTokens(res.token, res.refresh_token);
          const newAuthReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${res.token}`,
            },
          });
          return next(newAuthReq);
        }),
        catchError((refreshErr) => {
          loginService.logout();
          return throwError(() => refreshErr);
        })
      );
    })
  );
};

// ************************************************************
// Making the token refresh request skip the step of
// adding the authentication token (this is necessary
// because the API returns a 403 whenever the token is invalid)
// is done here by adding the X-Skip-Interceptor header to the
// request (in the service).
//
// Another possible solution is to handle a boolean called
// isRefreshing and handle its value before and after the
// http request (in the observable handling).
// ************************************************************
