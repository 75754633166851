<router-outlet />

<!-- TODO: Componentizar el infobox -->
@if (showingInfoBox()) {
  <div class="infobox-container">
  <div class="infobox-device">
    <i>
      <img src="assets/icons/warning.svg" alt="">
    </i>
    <div class="infobox-device__content">
      <h3>{{'ui.infoboxDevice.title' | translate}}</h3>
      <p>{{'ui.infoboxDevice.description' | translate}}</p>
  </div>
  <i>
    <button (click)="showingInfoBox.set(false)">
      <img src="assets/icons/close.svg" alt="">
    </button>
  </i>
  </div>
</div>
}


