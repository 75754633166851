import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { LoginStore } from '@core/store/login.store';

export const isLoggedInGuard: CanActivateFn = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

  if (state.url.includes('/login')) {
    return !inject(LoginStore).isLogged() || inject(Router).createUrlTree(['/evidences'])
  }

  return inject(LoginStore).isLogged() || inject(Router).createUrlTree(['/login']);
};
